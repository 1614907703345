import React from 'react';
import { Button, Divider } from 'antd';
import layoutImg from '../assests/images/layout.jpg';
import useAuth from '../utils/auth/useAuth';
import { logger } from '../utils/helpers';
import { SESSION_STORAGE_KEYS, SessionStorage } from '../utils/pingfederate/pingfederate';
import logo from '../assests/images/caddieLogo.png';

const LoginScreen: React.FC = (): JSX.Element => {
  // destructuring from useAuth hook
  const { login } = useAuth();

  const handleNeedHelpBtnClick = () => {
    /** Uses the `btoa()` function to encode the email address into Base64 format */
    const encodedEmail = 'Y2FkZGllaW5zaWdodHNAbGFuZG9sYWtlcy5jb20=';

    /** combining decoded email and subject, then assigning it to the `href` */
    window.location.href = `mailto:${atob(encodedEmail)}?subject=Assistance Required for Login`;
  };

  return (
    <div style={{ display: 'flex' }}>
      <Button className="helpButton" onClick={handleNeedHelpBtnClick}>
        Need Help ?
      </Button>
      <div
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div style={{ width: '50%' }}>
          <div style={{ marginTop: 40 }}>
            <img width={250} src={logo} alt="CaddieLogo" />
            <Divider
              style={{ marginBottom: 20, marginTop: 7, backgroundColor: 'var(--color-lightBlue)' }}
            />
          </div>
          <p
            style={{
              fontSize: 27,
              fontWeight: 700,
              letterSpacing: 1,
              textTransform: 'uppercase',
              color: 'var(--color-darkBlue)',
            }}
          >
            Sign in
          </p>
          <p style={{ fontSize: 17, marginBottom: 15, color: 'var(--color-darkBlue)' }}>
            Choose your user type.
          </p>
          <div style={{ display: 'flex', flexDirection: 'column', width: 'fit-content' }}>
            {/* Purina employee login button */}
            <Button
              size="large"
              style={{
                fontWeight: 500,
                fontSize: 13,
                textTransform: 'uppercase',
              }}
              className="buttonColorRed"
              onClick={() => {
                if (process.env.REACT_APP_PINGFEDERATE_INTERNAL_CLIENT_ID) {
                  // Save the internal client id to session storage for use in redirect callback to fetch access token
                  SessionStorage.save(
                    SESSION_STORAGE_KEYS.clientId,
                    process.env.REACT_APP_PINGFEDERATE_INTERNAL_CLIENT_ID,
                  );

                  // user is redirected to internal login page
                  login().catch((error) => {
                    logger(error as Error);
                  });
                }
              }}
            >
              Purina employee
            </Button>
            <div
              style={{
                fontSize: 16,
                margin: '15px 0px',
                fontWeight: 'bold',
                color: 'var(--color-darkBlue)',
              }}
            >
              OR
            </div>
            {/* Coop/dealer employee or customer login button */}
            <Button
              size="large"
              style={{
                fontWeight: 500,
                fontSize: 13,
                textTransform: 'uppercase',
              }}
              className="buttonColorRed"
              onClick={() => {
                if (process.env.REACT_APP_PINGFEDERATE_EXTERNAL_CLIENT_ID) {
                  // Save the external client id to session storage for use in redirect callback to fetch access token
                  SessionStorage.save(
                    SESSION_STORAGE_KEYS.clientId,
                    process.env.REACT_APP_PINGFEDERATE_EXTERNAL_CLIENT_ID,
                  );

                  // user is redirected to external login page
                  login().catch((error) => {
                    logger(error as Error);
                  });
                }
              }}
            >
              Coop/dealer employee or customer
            </Button>
          </div>

          <Divider
            style={{ marginBottom: 0, backgroundColor: 'var(--color-lightBlue)', marginTop: 45 }}
          />
          {/* New user registration */}
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <a
              href={process.env.REACT_APP_USER_REGISTRATION_URL}
              style={{
                marginTop: 8,
                color: '#102030',
              }}
              target="_blank"
              rel="noreferrer"
            >
              New User Registration
            </a>
          </div>
        </div>
      </div>
      <img
        src={layoutImg}
        alt="layoutImg"
        style={{ objectFit: 'cover', maxHeight: '100vh', minWidth: '40vw' }}
      />
    </div>
  );
};

export default LoginScreen;
